html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address,
big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center,
dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time,
mark, audio, video {
    margin: 0;
    border: 0;
    padding: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

*, *:before, *:after {
    box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

html, html a {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(black, 0.004);
}

body, input, textarea, select, button {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

a, a:visited {
    color: #2196f3;
}

ol, ul {
    list-style: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

button {
    cursor: pointer;
}

label {
    margin: 0;
    display: block;
    font-size: 16px;
    font-weight: 500;
}

:focus {
    outline: 0;
}

[type="checkbox"], [type="radio"] {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.row, .column {
    box-sizing: border-box;
}

.row:before, .row:after {
    content: '';
    display: table;
}

.row:after {
    clear: both;
}

.column {
    float: left;
    display: block;
    position: relative;
}

.column-1,
.column-2,
.column-3,
.column-4,
.column-5,
.column-6,
.column-7,
.column-8,
.column-9,
.column-10,
.column-11,
.column-12 {
    float: none;
    width: auto;
}

.column + .column {
    margin-left: 0;
}

@media only screen and (min-width: 768px) {
    .column-1,
    .column-2,
    .column-3,
    .column-4,
    .column-5,
    .column-6,
    .column-7,
    .column-8,
    .column-9,
    .column-10,
    .column-11,
    .column-12 {
        float: left;
    }

    .column + .column {
        margin-left: 1.6%;
    }
    .column-1 {
        width: 6.86666666667%;
    }
    .column-2 {
        width: 15.3333333333%;
    }
    .column-3 {
        width: 23.8%;
    }
    .column-4 {
        width: 32.2666666667%;
    }
    .column-5 {
        width: 40.7333333333%;
    }
    .column-6 {
        width: 49.2%;
    }
    .column-7 {
        width: 57.6666666667%;
    }
    .column-8 {
        width: 66.1333333333%;
    }
    .column-9 {
        width: 74.6%;
    }
    .column-10 {
        width: 83.0666666667%;
    }
    .column-11 {
        width: 91.5333333333%;
    }
    .column-12 {
        width: 100%;
        margin-left: 0;
    }
}

// bb-foundation resets.

.bb-button.b-primary, .b-primary {
    color: white !important;
    border-color: #098f33 !important;
    background-color: #21cf60 !important;

    &:hover {
        background-color: #21cf60 !important;
    }

    &:focus, &:active {
        box-shadow: 0 6px 6px -6px rgba(black, 0.1), 0 0 0 0.2rem rgba(#21cf60, 0.5);
    }
}

.b-text {
    color: #848f99;
    font-size: 14px;
    font-weight: 400;
}

.b-link {
    padding: 0;
    border: none;
    font-size: 14px;
    appearance: none;
    font-weight: 400;
    text-decoration: none;
    background-color: transparent;

    &, &:visited {
        color: #848f99;
        border-bottom: 1px dotted #848f99;
    }

    &.primary, &.primary:visited {
        color: #0092d1;
        border-bottom-color: #0092d1;
    }
}

a.b-link, button.b-link {
    &:disabled {
        cursor: default;
        pointer-events: none;
    }

    &:not(:disabled) {
        &:hover, &:focus {
            color: #0092d1;
            border-bottom: 1px solid #0092d1;
        }
    }
}

.bb-checkbox-input:checked + .bb-checkbox-label > span:first-child {
    border-color: #21cf60 !important;
    background-color: #21cf60 !important;
}

.bb-checkbox-input:not(:disabled):checked + .bb-checkbox-label:focus > span:first-child {
    box-shadow: 0 0 0 3px rgba(#21cf60, 0.5) !important;
}

.bb-dashboard-header {
    background-color: #21cf60 !important;
    border-bottom: 1px solid #098f33 !important;
}

.bb-dashboard-header-button {
    margin-right: 10px;
    border: 1px solid #098f33 !important;
}

.bb-table-progress {
    background-color: rgba(#21cf60, 0.3) !important;
}

.bb-table-progress .bb-table-indeterminate {
    background-color: #21cf60 !important;
}

.bb-pager-list-button.active, .bb-pager-list-button.active:hover {
    color: #21cf60 !important;
    border-color: #21cf60 !important;
}
